import { InviteCompanyForm } from '@/components/Company/InviteCompanyForm';
import InviteUserForm from '@/components/Company/InviteUserForm';
import { GetUsersByOrganizationDocument } from '@/components/Company/UserTable/getUsersByOrganization.generated';
import { Filter } from '@/components/modals/CreateNewObjectModal/CreateNewObjectModal';
import CreateTeamModal from '@/components/modals/CreateTeamModal/CreateTeamModal';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import {
  useGetUserCountAndModularObjectCountQuery,
} from '@/graphql/sideNavigation/getUserCountAndModularObjectCount.generated';
import { useLoggedInSubscription, useLoggedInUser, useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { type ExternalInviteFormState, usePostExternalOrgInviteMutation } from '@/state/queries/invites.api';
import metrics from '@/util/metrics';
import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowRight,
  faCube,
  faPlus,
  faUserPlus,
  faUsersLine,
  type IconDefinition,
} from '@fortawesome/sharp-solid-svg-icons';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { Fragment, type PropsWithChildren } from 'react';
import { CreateNewObjectModal } from '../../modals';
import InfoTooltip from '../../tooltip/InfoTooltip';
import { useSidenavContext } from '../Sidenav.context';

interface NewObjectButtonProps {
  expandSidenav: () => void;
}
export default function NewObjectButton ({ expandSidenav }: Readonly<NewObjectButtonProps>): JSX.Element {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { showModal, closeModal } = useModal();
  const { isExpanded } = useSidenavContext();
  const subscription = useLoggedInSubscription();
  const currentUser = useLoggedInUser();
  const { data: userAndItemCount } = useGetUserCountAndModularObjectCountQuery();

  const { isAdmin, isSuper, isManager } = useLoggedInUserRole();
  const { teams: canAccessTeamsFeature } = useFlags();
  const canAddTeam = canAccessTeamsFeature && (isAdmin || isSuper || isManager);

  const [submitExternalInvite] = usePostExternalOrgInviteMutation();
  const handleOnInviteCallback = async () => {
    await apolloClient.refetchQueries({ include: [GetUsersByOrganizationDocument] });
  };

  const handleSubmitExternalInvite = async (formState: ExternalInviteFormState) =>
    await submitExternalInvite(formState);

  const handleClick = (): void => {
    expandSidenav();
    metrics.track('sidenav - "New" button clicked', {
      currentPath: router.pathname,
    });
  };

  const handleNewObjectClick = (): void => {
    showModal(<CreateNewObjectModal filteredBy={Filter.Builds} />, {
      isMegaModal: true,
    });
    metrics.track('sidenav - new build clicked', {
      currentPath: router.pathname,
    });
  };

  const handleNewTaskClick = (): void => {
    showModal(<CreateNewObjectModal filteredBy={Filter.Drivers} />, {
      isMegaModal: true,
    });
    metrics.track('sidenav - new driver clicked', {
      currentPath: router.pathname,
    });
  };

  const handleNewTeamClick = (): void => {
    showModal(<CreateTeamModal closeModal={closeModal} />, {
      className: 'max-w-[514px] !overflow-y-visible',
    });
    metrics.track('sidenav - new team clicked', {
      currentPath: router.pathname,
    });
  };

  const handleNewInternalUserClick = () => {
    showModal(
      <InviteUserForm
        currentUser={currentUser}
        onInviteCallback={handleOnInviteCallback}
        closeModal={closeModal}
      />,
      { className: 'w-[750px] !max-w-[750px]' },
    );
    metrics.track('sidenav - new internal user clicked', {
      currentPath: router.pathname,
    });
  };

  const handleNewExternalCollaboratorClick = () => {
    showModal(
      <InviteCompanyForm
        submitExternalInvite={handleSubmitExternalInvite}
        closeModal={closeModal}
      />,
      { className: 'w-[750px] !max-w-[750px]' },
    );
    metrics.track('sidenav - new external collaborator clicked', {
      currentPath: router.pathname,
    });
  };

  const areBuildsRestricted = subscription?.type === 'basic' &&
    userAndItemCount?.getUserCountAndModularObjectCount?.buildCount > subscription?.featureLimits?.OBJECTS_BUILDS;
  const areDriversRestricted = subscription?.type === 'basic' &&
    userAndItemCount?.getUserCountAndModularObjectCount?.driverCount > subscription?.featureLimits?.OBJECTS_DRIVERS;
  const isInternalInviteRestricted = subscription?.type === 'basic' &&
    userAndItemCount?.getUserCountAndModularObjectCount?.userCount > subscription?.featureLimits?.USERS;

  return (
    <InfoTooltip
      message='Add New'
      tooltipClassName={cx('effra-8', {
        'hidden': isExpanded,
      })}
      tooltipContentClassName='px-[6px] py-[4px]'
      placement='right'
      className='relative'
    >
      <Menu as={Fragment}>
        {({ open }) => (
          <>
            {/* Dropdown Button */}
            <MenuButton
              id='create-new'
              data-testid='create-new-btn'
              className={cx(
                'flex justify-center @expanded/sidenav:w-full @expanded/sidenav:h-[unset] font-bold bg-white border text-primary rounded-[2px] effra-10 py-[8px] gap-[4px] border-gray-90 w-[24px] h-[24px] items-center hover:bg-gray-90',
                {
                  'opacity-80': open,
                },
              )}
              onClick={handleClick}
            >
              <FontAwesomeIcon className='text-[12px] @expanded/sidenav:text-[10px]' icon={faPlus} />
              <span className='hidden @expanded/sidenav:block'>New</span>
            </MenuButton>

            {/* Dropdown Menu */}
            <DropdownTransition>
              <MenuItems className='absolute left-0 z-50 mt-2 w-full bg-white divide-y divide-gray-100 ring-1 shadow-lg origin-top-right focus:outline-none rounded-[2px] ring-black/5 overflow-clip'>
                <NewMenuItem
                  name='Build'
                  icon={faCube}
                  onClick={handleNewObjectClick}
                  isProRestricted={areBuildsRestricted}
                />
                <NewMenuItem
                  name='Driver'
                  icon={faCircleArrowRight}
                  onClick={handleNewTaskClick}
                  isProRestricted={areDriversRestricted}
                />
                {canAddTeam && (
                  <NewMenuItem
                    name='Team'
                    icon={faUsersLine}
                    onClick={handleNewTeamClick}
                  />
                )}
                <NewMenuItem
                  name={`Add to ${currentUser?.organization?.name || 'your organization'}`}
                  icon={faUserPlus}
                  iconClassName='text-primary'
                  onClick={handleNewInternalUserClick}
                  isProRestricted={isInternalInviteRestricted}
                />
                <NewMenuItem
                  name='Invite to Integrate'
                  icon={faUserPlus}
                  iconClassName='text-tertiary'
                  onClick={handleNewExternalCollaboratorClick}
                />
              </MenuItems>
            </DropdownTransition>
          </>
        )}
      </Menu>
    </InfoTooltip>
  );
}

function DropdownTransition ({ children }: PropsWithChildren): JSX.Element {
  return (
    <Transition
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      {children}
    </Transition>
  );
}

interface NewMenuItemProps {
  name: string;
  icon: IconDefinition;
  iconClassName?: string;
  href?: string;
  onClick?: (e) => void;
  isProRestricted?: boolean;
}

function NewMenuItem (
  { name, icon, iconClassName, href, onClick, isProRestricted, ...otherProps }: Readonly<NewMenuItemProps>,
): JSX.Element {
  const router = useRouter();
  const handleOnClick = async (e) => {
    if (onClick) {
      onClick(e);
    } else if (href) {
      await router.push(href);
    }
  };

  return (
    <MenuItem {...otherProps}>
      {({ focus }) => (
        <button
          onClick={isProRestricted ? undefined : handleOnClick}
          className={cx(
            'group flex w-full items-center p-[8px] effra-12 gap-[8px] transition-all overflow-hidden',
            {
              'bg-gray-90': focus,
              'cursor-not-allowed': Boolean(isProRestricted),
            },
          )}
        >
          <span className='flex overflow-hidden items-center shrink gap-[8px]'>
            <FontAwesomeIcon
              icon={icon}
              className={cx(iconClassName, {
                'opacity-30': Boolean(isProRestricted),
              })}
            />
            <span
              className={cx('truncate', {
                'opacity-30': Boolean(isProRestricted),
              })}
            >
              {name}
            </span>
          </span>
          <span className='grow'>
            {isProRestricted && <ProBadge shouldExpand={true} />}
          </span>
        </button>
      )}
    </MenuItem>
  );
}
