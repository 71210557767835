import UserName from '@/components/history/TimelineEvent/UserName';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { getPrettyDateString } from '@/util/time.functions';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import type { GetObjectCardUpdatesSectionQuery } from './getObjectCardUpdatesSection.generated';

// File is the module

type updatesSectionItem = GetObjectCardUpdatesSectionQuery['getObjectCardUpdatesSection']['edges'][number];

interface updatesSectionItemProps {
  update: updatesSectionItem;
}

// function definition is the component
export default function UpdatesSectionItem ({ update }: updatesSectionItemProps) {
  // TODO - destruct this to pass only id and orgID into UserName()?
  const currentUser = useLoggedInUser();

  const categoryToCopy = {
    'modular_object_creation': 'created this object',
    'comment_created': 'left a comment',
    'links_added': 'added links',
    'links_deleted': 'deleted links',
    'files_added': 'added files',
    'files_deleted': 'deleted files',
    'standard_field_value_update': 'updated a field',
    'custom_field_added': 'added a custom field',
    'custom_field_value_updated': 'updated a custom field',
    'custom_field_removed': 'removed a custom field',
    'dependency_created': 'created a dependency',
    'dependency_type_updated': 'updated a dependency type',
    'dependency_triggered_update': 'triggered a dependency update',
    'approvals_enabled': 'enabled approvals',
    'approvals_disabled': 'disabled approvals',
    'request_submitted': 'submitted an approval request',
    'request_approved': 'approved a request',
    'request_denied': 'denied a request',
  };

  // this is the jsx we're returning
  return (
    <div key={uuidv5(uuidv4(), update.resourceID)} className='flex gap-[4px] items-center'>
      <ProfileImage
        user={{
          organizationId: update.userOrganizationID,
          profileImage: update.userProfileImage,
        }}
      />

      <UserName
        user={{
          id: update.userID,
          organizationId: update.userOrganizationID,
          firstName: update.userFirstName,
          lastName: update.userLastName,
        }}
        currentUser={currentUser}
      />

      <div>{categoryToCopy[update.updateCategory]}</div>

      <span
        className='mr-2 text-sm text-gray-ghost'
        data-chromatic='ignore'
      >
        <div title={update.timestamp}>{getPrettyDateString(update?.timestamp)}</div>
      </span>
    </div>
  );
}
