import {
  useGetObjectCardUpdatesSectionQuery,
} from '@/components/cards/UpdatesSection/getObjectCardUpdatesSection.generated';
import UpdatesSectionItem from '@/components/cards/UpdatesSection/UpdatesSectionItem';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

// This is the component
export default function UpdatesSection ({ objectID: id, paginationInput }): JSX.Element {
  const { data } = useGetObjectCardUpdatesSectionQuery({
    variables: {
      id,
      paginationInput: {
        offset: 0,
        limit: 200,
        time: null,
        direction: 'FORWARD',
      },
    },
  });

  const updates = data?.getObjectCardUpdatesSection?.edges;

  /*
    TODO:...
      1. Mouseover for timestamp
  */

  // This is the jsx
  return (
    <div className='flex flex-col gap-[10px]'>
      {updates?.map(
        (update) => <UpdatesSectionItem key={uuidv5(uuidv4(), update.resourceID)} update={update} />,
      )}
    </div>
  );
}
