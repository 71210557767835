import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetObjectCardUpdatesSectionQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
  paginationInput: Types.OffsetPaginationInput;
}>;


export type GetObjectCardUpdatesSectionQuery = { __typename?: 'Query', getObjectCardUpdatesSection: { __typename?: 'PaginatedUpdates', edges: Array<{ __typename?: 'Update', resourceID: string | null, sourceTable: string | null, timestamp: any | null, updateCategory: string | null, userID: string | null, userFirstName: string | null, userLastName: string | null, userProfileImage: string | null, userOrganizationID: string | null } | null> | null, pageInfo: { __typename?: 'OffsetPageInfo', hasNextPage: boolean | null } | null } };


export const GetObjectCardUpdatesSectionDocument = gql`
    query GetObjectCardUpdatesSection($id: UUID!, $paginationInput: OffsetPaginationInput!) {
  getObjectCardUpdatesSection(id: $id, paginationInput: $paginationInput) {
    edges {
      resourceID
      sourceTable
      timestamp
      updateCategory
      userID
      userFirstName
      userLastName
      userProfileImage
      userOrganizationID
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetObjectCardUpdatesSectionQuery__
 *
 * To run a query within a React component, call `useGetObjectCardUpdatesSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectCardUpdatesSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectCardUpdatesSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useGetObjectCardUpdatesSectionQuery(baseOptions: Apollo.QueryHookOptions<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>(GetObjectCardUpdatesSectionDocument, options);
      }
export function useGetObjectCardUpdatesSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>(GetObjectCardUpdatesSectionDocument, options);
        }
export function useGetObjectCardUpdatesSectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>(GetObjectCardUpdatesSectionDocument, options);
        }
export type GetObjectCardUpdatesSectionQueryHookResult = ReturnType<typeof useGetObjectCardUpdatesSectionQuery>;
export type GetObjectCardUpdatesSectionLazyQueryHookResult = ReturnType<typeof useGetObjectCardUpdatesSectionLazyQuery>;
export type GetObjectCardUpdatesSectionSuspenseQueryHookResult = ReturnType<typeof useGetObjectCardUpdatesSectionSuspenseQuery>;
export type GetObjectCardUpdatesSectionQueryResult = Apollo.QueryResult<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetObjectCardUpdatesSectionQuery((req, res, ctx) => {
 *   const { id, paginationInput } = req.variables;
 *   return res(
 *     ctx.data({ getObjectCardUpdatesSection })
 *   )
 * })
 */
export const mockGetObjectCardUpdatesSectionQuery = (resolver: Parameters<typeof graphql.query<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>>[1]) =>
  graphql.query<GetObjectCardUpdatesSectionQuery, GetObjectCardUpdatesSectionQueryVariables>(
    'GetObjectCardUpdatesSection',
    resolver
  )
